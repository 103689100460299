export const readableDateTime = (date: string, isTime = true): string => {
  const [d, t] = date.split('T');
  const day = new Date(d).getDay();
  const onlyDate = d.split('-');
  const time = t.split(':');
  const month = [
    '?',
    'янв',
    'фев',
    'мар',
    'апр',
    'мая',
    'июн',
    'июл',
    'авг',
    'сен',
    'окт',
    'ноя',
    'дек',
  ];
  const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
  return isTime
    ? `${onlyDate[2]} ${month[+onlyDate[1]]}, ${days[day]}. ${time[0]}:${time[1]}`
    : `${onlyDate[2]} ${month[+onlyDate[1]]}, ${days[day]}.`;
};

export const readableDurationTime = (durationTime: number): string => {
  const ONE_HOUR = 60;
  const ONE_DAY = 24 * ONE_HOUR;
  if (durationTime < ONE_HOUR) {
    return `${durationTime} м.`;
  }
  if (durationTime < ONE_DAY) {
    return `${durationTime / ONE_HOUR} ч.`;
  }
  return `${durationTime / ONE_DAY} д.`;
};

export const generateHHMM = (): string[] => {
  const minutes = [0, 30].map((i) => (i > 9 ? i : `0${i}`));
  const hours = [];
  for (let h = 0; h <= 23; h++) {
    hours.push(h > 9 ? `${h}` : `0${h}`);
  }
  const times = [];
  for (let h = 0; h < hours.length; h++) {
    const hour = hours[h];
    for (let m = 0; m < minutes.length; m++) {
      const minute = minutes[m];
      times.push(`${hour}:${minute}`);
    }
  }
  return times;
};
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { menuItems } from "./menu-items";
import { useAuth } from "../../hooks/use-auth";

export const TOP_NAV_HEIGHT = 79;

export const TopNav = ({ onNavOpen }: any) => {
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  const location = useLocation();
  const navigate = useNavigate();

  const currentTab = menuItems.findIndex(
    (item) => item.path === location.pathname
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    navigate(menuItems[newValue].path);
  };

  const { user } = useAuth();
  const isUser = user && user !== "";

  return (
    <>
      <Box component="header">
        <Stack
          justifyContent="center"
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Stack
            justifyContent="center"
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              src="/dl-logo.jpg"
              variant="square"
              sx={(theme) => ({
                height: theme.typography.h1.fontSize,
                width: theme.typography.h1.fontSize,
              })}
            />
            <Typography variant="h2" color="primary.contrastText">
              Dilijan Logistics
            </Typography>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent={lgUp ? "center" : ""}
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
            backgroundColor: "primary.main",
            width: "100%",
          }}
        >
          {lgUp ? (
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              variant={"fullWidth"}
            >
              {menuItems
                .filter((item) =>
                  item.closed ? (isUser ? true : false) : true
                )
                .map((item, index) => (
                  <Tab
                    key={index}
                    label={item.title}
                    icon={item.icon}
                    sx={{
                      minWidth: 140,
                      "&.Mui-selected": {
                        backgroundColor: "secondary.main",
                      },
                    }}
                  />
                ))}
            </Tabs>
          ) : (
            <IconButton
              onClick={onNavOpen}
              sx={{
                backgroundColor: "primary.main",
                color: "secondary.main",
                "&:hover": {
                  backgroundColor: "secondary.main",
                  color: "primary.main",
                },
              }}
            >
              <SvgIcon fontSize="medium" sx={{ color: "inherit" }}>
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
      </Box>
    </>
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func,
};

import { OrderCreateForm } from '../../reducers/order';
import { Order, Trip } from '../interfaces';
import { AxiosConnector } from './axiosConnector';

export class OrderApi extends AxiosConnector {

  async create(data: OrderCreateForm) {
    await this.axios.post(`${this.uri}order`, data).catch(this.handleError);
  }

  async cancel(idOrder: number) {
    await this.axios.post(`${this.uri}order/cancel`, { idOrder }).catch(this.handleError);
  }

  async list(): Promise<Order[]> {
    const result = await this.axios.get<Order[]>(`${this.uri}order/`).catch<any>(this.handleError);
    const { data } = result;
    return data;
  }

  async history(): Promise<Order[]> {
    const result = await this.axios
      .get<Order[]>(`${this.uri}order/history`)
      .catch<any>(this.handleError);
    const { data } = result;
    return data;
  }

  async createByManager(data: OrderCreateForm) {
    await this.axios.post(`${this.uri}order/manager`, data).catch(this.handleError);
  }

  async listByIdTrip(idTrip: number): Promise<Order[]> {
    const result = await this.axios
      .get<Trip[]>(`${this.uri}order/${idTrip}`)
      .catch<any>(this.handleError);
    const { data } = result;
    return data;
  }

  async updateTrip(body: { id: number; idTrip: number }): Promise<void> {
    await this.axios.post(`${this.uri}order/trip`, body).catch<any>(this.handleError);
  }

}

import { createSlice } from '@reduxjs/toolkit';
import { OrderStatus } from '../enums/OrderStatus';
import { TripType } from '../enums/TripType';
import { Order } from '../sdk/interfaces';
import { RootState } from '../store';

interface OrderState {
  activeOrders: Order[];
  historyOrders: Order[];
}

export interface OrderCreateForm {
  idDestination?: number;
  to: { id?: number; address: string };
  from: { id?: number; address: string };
  numberOfBookedSeats: number;
  numberOfBookedCarSeats: number;
  numberOfBookedLuggages: number;
  date?: string;
  type: TripType | null;
  time?: string;
  comment: string;
  phone: string | null;
}

export const initialOrderCreate: OrderCreateForm = {
  // idDestination: 0,
  // date: '',
  // time: '',
  from: { address: '', id: undefined },
  to: { address: '', id: undefined },
  numberOfBookedSeats: 1,
  numberOfBookedCarSeats: 0,
  numberOfBookedLuggages: 0,
  comment: '',
  type: null,
  phone: null,
};

const initialState: OrderState = {
  activeOrders: [],
  historyOrders: [],
};

export const orderReducer = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setActiveOrders: (state: any, action: any) => {
      state.activeOrders = action.payload;
    },
    setHistoryOrders: (state: any, action: any) => {
      state.historyOrders = action.payload;
    },
  },
});

export const { setActiveOrders, setHistoryOrders } = orderReducer.actions;

const UNFINISHED_STATUSES = [OrderStatus.IN_PROGRESS, OrderStatus.APPROVED, OrderStatus.PAID];

export const selectActiveOrders = (status?: OrderStatus) => {
  return (state: RootState): Order[] => {
    if (status) {
      return state.order.activeOrders.filter((order: any) => {
        if (UNFINISHED_STATUSES.includes(status)) {
          return (
            order.status === OrderStatus.IN_PROGRESS ||
            order.status === OrderStatus.APPROVED ||
            order.status === OrderStatus.PAID
          );
        }
        return order.status === status;
      });
    }
    return state.order.activeOrders;
  };
};
export const selectHistoryOrders = (state: RootState): Order[] => state.order.historyOrders;

export default orderReducer.reducer;

import { Box, Button, CardActions, Chip , Typography} from '@mui/material';

const PaymentFailed = ({ handleReturnToApp }: { handleReturnToApp: () => void, }) => {
 
  return (
    <Box textAlign="center" mt={3}>
    <Chip label="Ошибка платежа" color="error" />
    <CardActions sx={{ justifyContent: 'center', marginTop: 2 }}>
      <Button variant="contained" onClick={handleReturnToApp}>
        Вернуться в приложение
      </Button>
    </CardActions>
    <Typography variant="body1" mt={2}>
      В мобильном приложении Вы сможете оплатить наличными или отменить заказ.
    </Typography>
  </Box>
  );
};

export default PaymentFailed;

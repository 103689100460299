export enum TripStatus {
  IN_PROGRESS = 'inProgress',
  APPROVED = 'approved',
  PAID = 'paid',
  CURRENT = 'current',
  FINISHED = 'finished',
  MISSED_BY_PASSENGER = 'missedByPassenger',
  CANCELED_BY_DRIVER = 'canceledByDriver',
  CANCELED_BY_MANAGER = 'canceledByManager',
}

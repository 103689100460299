import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface Login {
  user: User;
}
interface User {
  token: string | null;
  phone: string | null;
}

const initialState: Login = {
  user: { token: null, phone: null },
};

export const loginReducer = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUser: (state: any, action: any) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = loginReducer.actions;

export const selectUser = (state: RootState): User => state.login.user;

export default loginReducer.reducer;

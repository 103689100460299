import { Car, CarInput } from '../interfaces';
import { AxiosConnector } from './axiosConnector';

export class CarApi extends AxiosConnector { 
  async list() {
    const result = await this.axios.get<Car[]>(`${this.uri}car`).catch<any>(this.handleError);
    return result.data;
  }

  async create(body: CarInput) {
    const result = await this.axios
      .post<Car[]>(`${this.uri}car`, body)
      .catch<any>(this.handleError);
    return result.data;
  }
}

import { AxiosConnector } from './axiosConnector'
import { Payment } from '../../reducers/payment'


export class PaymentApi extends AxiosConnector {
    async savePayment(data: Payment ) {
        await this.axios.post(`${this.uri}payment`, data).catch(this.handleError);
    }

    async getById(params: { id: string }) {
      const result = await this.axios.get(`${this.uri}payment/${params.id}`).catch<any>(this.handleError);
      return result.data;
    }

    async update(data: Payment) {
      await this.axios.post(`${this.uri}payment/update`, data).catch(this.handleError);
    }
    
    async cancel(data: Payment ) {
      await this.axios.post(`${this.uri}payment/cancel`, data).catch(this.handleError);
    }
  }
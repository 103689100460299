import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../sdk";
import CarCard from "../car/CarCard";
import NoCars from "../car/NoCars";
import { selectCars, setCars } from "../../reducers/car";
import CarCreate from "../car/CarCreate";
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";

const CarsPage = () => {
  const [isCarRefreshing, setIsCarRefreshing] = useState(false);
  const cars = useSelector(selectCars);
  const dispatch = useDispatch();

  const uploadCars = () => {
    setIsCarRefreshing(true);
    api.cars
      .list()
      .then((list) => {
        dispatch(setCars(list));
        setIsCarRefreshing(false);
      })
      .catch(() => {
        setIsCarRefreshing(false);
      });
  };

  useEffect(() => {
    uploadCars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isCarRefreshing) {
    return <CircularProgress />;
  }
  if (cars.length === 0) {
    return <NoCars />;
  }
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Typography variant="h4">Автопарк</Typography>
          <CarCreate />
          <Typography variant="h4">Всего машин:{cars.length} </Typography>
          {cars.length && cars.map((car) => <CarCard car={car} />)}
        </Stack>
      </Container>
    </Box>
  );
};

export default CarsPage;

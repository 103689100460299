import { AppStatusApi } from './api/appStatus';
import { CarApi } from './api/car';
import { CityApi } from './api/city';
import { DestinationApi } from './api/destination';
import { OrderApi } from './api/order';
import { TripApi } from './api/trip';
import { UserApi } from './api/user'
import {PaymentApi} from './api/payment';

export class Api {
  user: UserApi;
  cars: CarApi;
  order: OrderApi;
  appStatus: AppStatusApi;
  trip: TripApi;
  city: CityApi;
  destination: DestinationApi;
  payment: PaymentApi;
  
  constructor() {
    this.user = new UserApi();
    this.trip = new TripApi();
    this.destination = new DestinationApi();
    this.order = new OrderApi();
    this.appStatus = new AppStatusApi();
    this.cars = new CarApi();
    this.payment=new PaymentApi();
    this.city = new CityApi();
  }

  setToken(token: string) {
    this.user.updateToken(token);
    this.cars.updateToken(token);
    this.trip.updateToken(token);
    this.destination.updateToken(token);
    this.order.updateToken(token);
    this.appStatus.updateToken(token);
    this.payment.updateToken(token);
    this.city.updateToken(token);
  }
}

export const api = new Api();

import { createSlice } from '@reduxjs/toolkit';
import { OrderStatus } from '../enums/OrderStatus';
import { TripType } from '../enums/TripType';
import { RootState } from '../store';

interface OrderScreenState {
  tripType: TripType;
  orderStatus: OrderStatus;
  countOrderApproved: number;
  countOrderCurrent: number;
}

const initialState: OrderScreenState = {
  tripType: TripType.TAXI_SEAT,
  orderStatus: OrderStatus.APPROVED,
  countOrderApproved: 0,
  countOrderCurrent: 0,
};

export const requestScreenReducer = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setTripType: (state: any, action: any) => {
      state.tripType = action.payload;
    },
    setOrderStatus: (state: any, action: any) => {
      state.orderStatus = action.payload;
    },
    setCountOrderApproved: (state: any, action: any) => {
      state.countOrderApproved = action.payload;
    },
    setCountOrderCurrent: (state: any, action: any) => {
      state.countOrderCurrent = action.payload;
    },
  },
});

export const { setTripType, setOrderStatus, setCountOrderApproved, setCountOrderCurrent } =
  requestScreenReducer.actions;

export const selectTripType = (state: RootState): TripType => state.request.tripType;
export const selectOrderStatus = (state: RootState): OrderStatus => state.request.orderStatus;
export const selectCountTripOrdered = (state: RootState): number =>
  state.request.countOrderApproved;
export const selectCountTripCurrent = (state: RootState): number => state.request.countOrderCurrent;

export default requestScreenReducer.reducer;

import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";

export const AuthGuard = (props: any) => {
  const { children } = props;
  const { user } = useAuth();

  if (!user || user === "") {
    return <Navigate to="/" replace />;
  }
  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

import { AxiosConnector } from './axiosConnector';

export class AppStatusApi extends AxiosConnector {

  async get(version: string) {
    const result = await this.axios
      .get<{ isSupported: boolean }>(`${this.uri}appStatus/${version}`)
      .catch<any>(this.handleError);
    return result.data;
  }
}

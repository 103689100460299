import { Box, Button, Stack, Typography } from "@mui/material";
import { Field, getFormValues, reduxForm } from "redux-form";
import { LENGTH_ARMENIAN_NUMBER_WITHOUT_CODE } from "../../utils/constants";
import { useState } from "react";
import { api } from "../../sdk";
import renderInput from "../html/renderInput";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";

const LoginPage = ({ handleSubmit }: { handleSubmit: any }) => {
  const [isDisableLogin, setIsDisableLogin] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const loginPage = useSelector<any, any>(getFormValues("loginPage"));
  const auth = useAuth();
  const sendCode = async ({ phone }: any) => {
    setErrorMessage("");
    try {
      await api.user.code({ phone });
      setIsCodeSent(true);
      setIsDisableLogin(true);
      setTimeout(() => setIsDisableLogin(false), 60000);
    } catch (error: any) {
      return setErrorMessage(error.message);
    }
  };
  const login = async ({ code, phone }: any) => {
    setErrorMessage("");
    try {
      const token = await api.user.authorization({ code, phone });
      auth.login(token);
      setIsLogin(true);
    } catch (error: any) {
      return setErrorMessage(error.message);
    }
  };

  if (isLogin) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        flex: "1 1 auto",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: 550,
          px: 3,
          py: "100px",
          width: "100%",
        }}
      >
        <div>
          <Stack spacing={1} sx={{ mb: 3 }}>
            <Typography variant="h4">Login</Typography>
          </Stack>
          <Stack spacing={3}>
            <Field
              fullWidth
              component={renderInput}
              label="Phone"
              name="phone"
              maxLength={LENGTH_ARMENIAN_NUMBER_WITHOUT_CODE}
            />
            {isCodeSent ? (
              <Field
                fullWidth
                component={renderInput}
                label="Код подтверждения"
                name="code"
              />
            ) : null}
          </Stack>
          {errorMessage}
          {isCodeSent ? (
            <Button
              fullWidth
              size="large"
              sx={{ mt: 3 }}
              variant="contained"
              onClick={handleSubmit(login)}
            >
              Войти
            </Button>
          ) : null}
          <Button
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            variant="contained"
            disabled={
              loginPage?.phone.length !== LENGTH_ARMENIAN_NUMBER_WITHOUT_CODE ||
              isDisableLogin
            }
            onClick={handleSubmit(sendCode)}
          >
            Отправить код
          </Button>
        </div>
      </Box>
    </Box>
  );
};

const validate = (values: any) => {
  const errors = {} as any;
  if (values) {
    if (
      values.phone &&
      values.phone.length !== LENGTH_ARMENIAN_NUMBER_WITHOUT_CODE
    ) {
      errors.phone = "Неверный номер";
    }
  }
  return errors;
};
export default reduxForm({
  form: "loginPage",
  validate,
})(LoginPage);

import { useState } from "react";
import { api } from "../sdk";

export const useLocalStorage = (keyName: string, defaultValue: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = localStorage.getItem(keyName);
      if (value) {
        const parsedValue = JSON.parse(value);
        api.setToken(parsedValue);
        return parsedValue;
      } else {
        localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue: string) => {
    try {
      localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    api.setToken(newValue);
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};

import { User } from "../interfaces";
import { AxiosConnector } from "./axiosConnector";

export class UserApi extends AxiosConnector {
  async authorization({
    phone,
    role = "manager",
    code,
  }: {
    phone: string;
    code: string;
    role?: string;
  }): Promise<string> {
    const result = await this.axios
      .post<{ token: string }>(`${this.uri}user/authorization`, {
        phone,
        role,
        code,
      })
      .catch<any>(this.handleError);
    const { token } = result.data;
    return token;
  }

  async code({ phone, role = "manager" }: { phone: string; role?: string }) {
    await this.axios
      .post(`${this.uri}user/code`, {
        phone,
        role,
      })
      .catch(this.handleError);
  }

  async listDrivers(): Promise<User[]> {
    const result = await this.axios
      .get<User[]>(`${this.uri}user/drivers`)
      .catch<any>(this.handleError);
    return result.data;
  }

  async deleteUser({
    phone,
    code,
    role,
  }: {
    phone: string;
    code: string;
    role: string;
  }) {
    await this.axios
      .delete(`${this.uri}user`, {
        data: {
          phone,
          code,
          role,
        },
      })
      .catch(this.handleError);
  }
}

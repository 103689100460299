import { Card, CardContent, Chip, Grid } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import { Car } from "../../sdk/interfaces";
import Seats from "../order/Seats";

const CarCard = ({ car }: { car: Car }) => (
  <Card>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {car.numberOfSeats >= 7 ? (
            <AirportShuttleIcon htmlColor={car.color} />
          ) : (
            <DirectionsCarIcon htmlColor={car.color} />
          )}
          <Chip label={`${car.licensePlate}`} />

          {car.model}
        </Grid>
        <Grid item xs={12}>
          <Seats
            numberOfBookedCarSeats={car.numberOfSeats}
            numberOfBookedLuggages={car.numberOfLuggages}
            numberOfBookedSeats={car.numberOfSeats}
          />
        </Grid>
        <Grid item xs={12}>
          Водитель: {car.driver.name} <br /> Номер телефона: +374
          {car.driver.phone}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
export default CarCard;

import { Trip } from "../interfaces";
import { AxiosConnector } from "./axiosConnector";

export class TripApi extends AxiosConnector {
  async list(): Promise<Trip[]> {
    const result = await this.axios
      .get<Trip[]>(`${this.uri}trip/`)
      .catch<any>(this.handleError);
    return result.data;
  }
  async history(): Promise<Trip[]> {
    const result = await this.axios
      .get<Trip[]>(`${this.uri}trip/history`)
      .catch<any>(this.handleError);
    return result.data;
  }

  async updateCar(body: { id: number; idCar: number }): Promise<void> {
    await this.axios
      .post(`${this.uri}trip/car`, body)
      .catch<any>(this.handleError);
  }

  async finish(body: { idTrip: number; amount: number }) {
    await this.axios
      .post(`${this.uri}trip/finish`, body)
      .catch(this.handleError);
  }
  async start(body: { idTrip: number }) {
    await this.axios
      .post(`${this.uri}trip/start`, body)
      .catch(this.handleError);
  }

  async cancel(idTrip: number) {
    await this.axios
      .post(`${this.uri}trip/cancel`, { idTrip })
      .catch(this.handleError);
  }
}

import { createSlice } from '@reduxjs/toolkit';
import { TripType } from '../enums/TripType';
import { Destination } from '../sdk/interfaces';
import { RootState } from '../store';
import { readableDateTime } from '../utils/date';

interface DestinationState {
  destinations: Destination[];
}

const initialState: DestinationState = {
  destinations: [],
};

export const destinationReducer = createSlice({
  name: 'destination',
  initialState,
  reducers: {
    setDestinations: (state: any, action: any) => {
      state.destinations = action.payload;
    },
  },
});

export const { setDestinations } = destinationReducer.actions;

export const selectDestinations = (type: TripType) => {
  return (state: RootState): Destination[] => {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);
    const inXDays = new Date();
    inXDays.setUTCHours(0, 0, 0, 0);
    inXDays.setDate(inXDays.getDate() + 10);
    return state.destination.destinations
      .filter((d: any) => d.type === type)
      .map((d: any) => ({
        ...d,
        dates: d.dates
          .filter((date: any) => {
            const checkedDate = new Date(date);
            return currentDate <= checkedDate && inXDays > checkedDate;
          })
          .map((value: any) => {
            return {
              label: readableDateTime(`${value}`, false),
              value,
            } as any;
          }),
      }));
  };
};
export default destinationReducer.reducer;

import { Box, Container, Stack, Typography } from "@mui/material";

const ContactsPage = () => (
  <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Typography variant="h4">Контакты</Typography>
        </Stack>
        <a href="https://t.me/+BLPs7VhSLnIyODFi">Telegram</a>
        <br />
        +37493404579
        <br />
        +37499273744
      </Container>
    </Box>
  </>
);

export default ContactsPage;

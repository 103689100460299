import { OrderStatus } from '../enums/OrderStatus';
import { TripStatus } from '../enums/TripStatus';
import { Color } from '../type/color';

export const orderStatusMap = new Map<OrderStatus, { color: Color, message: string }>([
  [OrderStatus.IN_PROGRESS, { color: 'default', message: 'В обработке' }],
  [OrderStatus.APPROVED, { color: 'success', message: 'Подтверждена' }],
  [OrderStatus.PAID, { color: 'success', message: 'Подтверждена, оплачена' }],
  [OrderStatus.CURRENT, { color: 'default', message: 'В пути' }],
  [OrderStatus.FINISHED, { color: 'default', message: 'Завершена' }],
  [OrderStatus.MISSED_BY_PASSENGER, { color: 'error', message: 'Пропущена' }],
  [OrderStatus.CANCELED_BY_PASSENGER, { color: 'error', message: 'Отменена' }],
  [OrderStatus.CANCELED_BY_DRIVER, { color: 'error', message: 'Отменена водителем' }],
  [OrderStatus.CANCELED_BY_MANAGER, { color: 'error', message: 'Отменена диспетчером' }],
]);

export const orderStatusCompleteSet = new Set([
  OrderStatus.CANCELED_BY_PASSENGER,
  OrderStatus.CANCELED_BY_DRIVER,
  OrderStatus.CANCELED_BY_MANAGER,
  OrderStatus.FINISHED,
]);
export const orderStatusCancelSet = new Set([
  OrderStatus.CANCELED_BY_PASSENGER,
  OrderStatus.CANCELED_BY_DRIVER,
  OrderStatus.CANCELED_BY_MANAGER,
]);

export const tripStatusMap = new Map<TripStatus, { color: Color, message: string }>([
  [TripStatus.IN_PROGRESS, { color: 'default', message: 'В обработке' }],
  [TripStatus.APPROVED, { color: 'success', message: 'Подтверждена' }],
  [TripStatus.PAID, { color: 'success', message: 'Подтверждена, оплачена' }],
  [TripStatus.CURRENT, { color: 'default', message: 'В пути' }],
  [TripStatus.FINISHED, { color: 'default', message: 'Завершена' }],
  [TripStatus.MISSED_BY_PASSENGER, { color: 'error', message: 'Пропущена' }],
  [TripStatus.CANCELED_BY_DRIVER, { color: 'error', message: 'Отменена водителем' }],
  [TripStatus.CANCELED_BY_MANAGER, { color: 'error', message: 'Отменена диспетчером' }],
]);
export const tripStatusCompleteSet = new Set([
  TripStatus.FINISHED,
  TripStatus.CANCELED_BY_DRIVER,
  TripStatus.CANCELED_BY_MANAGER,
]);

export const tripStatusCancelSet = new Set([
  TripStatus.CANCELED_BY_DRIVER,
  TripStatus.CANCELED_BY_MANAGER,
]);

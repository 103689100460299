import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import { normalizePhone } from "../../utils/validation";
import renderInput from "../html/renderInput";
import { Field, getFormValues, reduxForm } from "redux-form";
import { api } from "../../sdk";
import { useState } from "react";
import { useSelector } from "react-redux";
import renderSelect from "../html/renderSelect";

const RevokePage = () => {
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const { phone, code, role } = useSelector<any, any>(
    getFormValues("revokeForm")
  );
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);

  const handleSendCode = async () => {
    setErrorMessage("");
    try {
      await api.user.code({ phone, role: "web" });
      setIsCodeSent(true);
    } catch (error: any) {
      setErrorMessage(`Ошибка при отправке кода: ${error.message}`);
    }
  };

  const handleDelete = async () => {
    setErrorMessage("");
    try {
      await api.user.deleteUser({ phone, code, role });
      setVisible(false);
      setIsDeleted(true);
    } catch (error: any) {
      setErrorMessage(`Ошибка при удалении аккаунта: ${error.message}`);
    }
  };
  const projects = [
    { label: "Vato-taxi", value: "passenger" },
    { label: "Vato-taxi-driver", value: "driver" },
    { label: "Dili-delivery", value: "shopper" },
  ];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="50vh"
      pt={2}
      pb={2}
    >
      {isDeleted ? (
        <Typography variant="h6">Ваш аккаунт успешно удален</Typography>
      ) : (
        <Button variant="contained" color="primary" onClick={showDialog}>
          Удалить аккаунт
        </Button>
      )}
      <Dialog open={visible} onClose={hideDialog}>
        <DialogTitle>Вы точно хотите удалить аккаунт?</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Field
              fullWidth
              name="phone"
              component={renderInput}
              label={"Введите номер телефона"}
              required
              normalize={normalizePhone}
            />
            <Field
              fullWidth
              name="role"
              component={renderSelect}
              label={"Выберете приложение"}
              list={projects.map((project: any) => ({
                label: project.label,
                value: project.value,
              }))}
              required
            />

            {isCodeSent && (
              <Field
                fullWidth
                name="code"
                component={renderInput}
                label={"Введите код подтверждения"}
                required
                normalize={normalizePhone}
              />
            )}
          </Stack>
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSendCode}>
            Запросить код
          </Button>
          <Button color="primary" onClick={hideDialog}>
            Отмена
          </Button>
          {isCodeSent && (
            <Button variant="contained" color="primary" onClick={handleDelete}>
              Удалить
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default reduxForm({
  form: "revokeForm",
  initialValues: { phone: "", code: "" },
})(RevokePage);

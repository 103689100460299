import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { reducer } from "redux-form";

import appReducer from "./reducers/app";
import carReducer from "./reducers/car";
import cityReducer from "./reducers/city";
import tripReducer from "./reducers/trip";
import loginScreenReducer from "./reducers/loginScreen";
import destinationReducer from "./reducers/destination";
import orderReducer from "./reducers/order";
import requestScreenReducer from "./reducers/orderScreen";
import tourReducer from "./reducers/tour";
import tourSearchReducer from "./reducers/searchTour";

export const store = configureStore({
  reducer: combineReducers({
    car: carReducer,
    city: cityReducer,
    trip: tripReducer,
    login: loginScreenReducer,
    request: requestScreenReducer,
    destination: destinationReducer,
    order: orderReducer,
    app: appReducer,
    tour: tourReducer,
    tourSearch: tourSearchReducer,
    form: reducer,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import PropTypes from "prop-types";
import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TikTokIcon from "@mui/icons-material/MusicNote";
import packageJson from "../../../package.json";

export const Footer = () => {
  console.log(`Version: ${packageJson.version}`);
  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: "primary.main",
      }}
    >
      <Typography variant="body1" align="center">
        © 2024 Dilijan Logistics
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Link
          component={RouterLink}
          to="/policy"
          color="inherit"
          sx={{ mr: 2 }}
        >
          Policy
        </Link>
        <Link component={RouterLink} to="/contacts" color="inherit">
          Контакты
        </Link>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Link
          href="https://vk.com/dilijan.logistics"
          color="inherit"
          sx={{ mr: 2 }}
        >
          VK
        </Link>
        <Link
          href="https://www.facebook.com/dilijan.logistics"
          color="inherit"
          sx={{ mr: 2 }}
        >
          <FacebookIcon />
        </Link>
        <Link
          href="https://www.instagram.com/dilijan.logistics"
          color="inherit"
          sx={{ mr: 2 }}
        >
          <InstagramIcon />
        </Link>
        <Link href="https://www.tiktok.com/@dilijan.logistics" color="inherit">
          <TikTokIcon />
        </Link>
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  onNavOpen: PropTypes.func,
};

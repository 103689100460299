import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  CarCreateForm,
  initialCarCreateForm,
  setCars,
} from "../../reducers/car";
import { api } from "../../sdk";
import renderInput from "../html/renderInput";
import renderSelect from "../html/renderSelect";
import { generateDropDown } from "../../utils/fakeData";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Snackbar,
} from "@mui/material";

const CarCreate = ({ handleSubmit }: { handleSubmit: any }) => {
  const [isDriversRefreshing, setIsDriversRefreshing] = useState(false);
  const [drivers, setDrivers] = useState<any>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isCarRefreshing, setIsCarRefreshing] = useState(false);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const uploadCars = () => {
    setIsCarRefreshing(true);
    api.cars
      .list()
      .then((list) => {
        dispatch(setCars(list));
        setIsCarRefreshing(false);
      })
      .catch(() => {
        setIsCarRefreshing(false);
      });
  };

  const uploadDrivers = () => {
    setIsDriversRefreshing(true);
    api.user
      .listDrivers()
      .then((list) => {
        setDrivers(list);
        setIsDriversRefreshing(false);
      })
      .catch(() => {
        setIsDriversRefreshing(false);
      });
  };

  useEffect(() => {
    uploadDrivers();
  }, []);
  const [errorMessage, setErrorMessage] = useState("");

  const createCar = async (values: any) => {
    setIsCreating(true);
    setErrorMessage("");
    try {
      await api.cars.create(values);
      await uploadCars();

      setIsCreating(false);
      setOpen(true); // Open the Snackbar
    } catch (error: any) {
      setIsCreating(false);
      return setErrorMessage(error.message);
    }
  };

  if (isDriversRefreshing) {
    return <CircularProgress />;
  }

  return (
    <Card>
      <CardHeader title="Создать машину" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Field
              fullWidth
              name="idDriver"
              component={renderSelect}
              label="Водитель"
              list={
                drivers.map((driver: any) => ({
                  label: `${driver.name} ${driver.phone}`,
                  value: `${driver.id}`,
                })) || []
              }
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              name="licensePlate"
              component={renderInput}
              label={"Гос номер"}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              name="model"
              component={renderInput}
              label={"Модель"}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              name="color"
              component={renderSelect}
              label={"Цвет машины"}
              list={["red", "green", "yellow", "grey", "black"].map(
                (color) => ({
                  label: color,
                  value: color,
                })
              )}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              name="numberOfSeats"
              component={renderSelect}
              label={"Количество посадочных мест"}
              list={generateDropDown([...(Array(100).keys() as any)])}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              name="numberOfLuggages"
              component={renderSelect}
              label={"Количество багажа"}
              list={generateDropDown([...(Array(100).keys() as any)])}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              name="price"
              component={renderInput}
              label={"Добавочная цена"}
              required
            />
            {errorMessage}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button variant="contained" onClick={handleSubmit(createCar)}>
          {isCreating && isCarRefreshing ? (
            <CircularProgress size={24} />
          ) : (
            "Создать"
          )}
        </Button>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
          message="Машина успешно добавлена!"
        />
      </CardActions>
    </Card>
  );
};
const validate = (values: CarCreateForm) => {
  const errors = {} as any;
  if (values) {
    if (values.idDriver === null) {
      errors.idDriver = "Поле обязательно к заполнению";
    }
    if (values.licensePlate === "") {
      errors.licensePlate = "Поле обязательно к заполнению";
    }
    if (values.color === "") {
      errors.color = "Поле обязательно к заполнению";
    }
    if (values.price < 0) {
      errors.price = "Поле обязательно к заполнению";
    }
    if (values.model === "") {
      errors.model = "Поле обязательно к заполнению";
    }
  }
  return errors;
};

export default connect(() => ({
  initialValues: initialCarCreateForm,
}))(
  reduxForm({
    form: "carCreateForm",
    validate,
  })(CarCreate)
);

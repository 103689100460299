
import { createSlice } from '@reduxjs/toolkit';

interface TourSearchState {
  searchTerm: string;
}

const initialState: TourSearchState = {
  searchTerm: '',
};

const tourSearchSlice = createSlice({
  name: 'tourSearch',
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
});

export const { setSearchTerm } = tourSearchSlice.actions;

export default tourSearchSlice.reducer;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoTrips from "../trip/NoTrips";
import { selectCars } from "../../reducers/car";
import { api } from "../../sdk";
import { readableDateTime } from "../../utils/date";
import TripRow from "../trip/TripRow";
import { selectHistoryTrips, setHistoryTrips } from "../../reducers/trip";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Trip } from "../../sdk/interfaces";
import { withAuthGuard } from "../../hocs/with-auth-guard";

const TripsPage = () => {
  const [isTripRefreshing, setIsTripRefreshing] = useState(false);
  const trips = useSelector<any, Trip[]>(selectHistoryTrips);
  const cars = useSelector(selectCars);
  const dispatch = useDispatch();

  const uploadTrips = () => {
    setIsTripRefreshing(true);
    api.trip
      .history()
      .then((list: Trip[]) => {
        dispatch(setHistoryTrips(list));
        setIsTripRefreshing(false);
      })
      .catch((err: Error) => {
        if (err.message === "Unauthorized request") {
          api.setToken("");
          // LOGOUT
        }
        setIsTripRefreshing(false);
      });
  };

  useEffect(() => {
    uploadTrips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isTripRefreshing) {
    return <CircularProgress />;
  }
  if (trips.length === 0) {
    return <NoTrips />;
  }
  const datesOfTrips = Array.from(new Set(trips.map((t) => t.date)));
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        {datesOfTrips.map((date) => (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center" style={{ margin: 15 }}>
                {readableDateTime(date)}
              </Typography>
            </Grid>
            {trips
              .filter((t) => t.date === date)
              .map((trip) => (
                <TripRow key={trip.id} tripDefault={trip} cars={cars} />
              ))}
          </Grid>
        ))}
      </Container>
    </Box>
  );
};

export default withAuthGuard(TripsPage);

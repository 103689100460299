import { createSlice } from '@reduxjs/toolkit';
import { ThemeMode } from '../enums/ThemeMode';
import { RootState } from '../store';

interface App {
  themeMode: ThemeMode;
  token: string;
  countOfTrips: number;
}

const initialState: App = {
  themeMode: ThemeMode.LIGHT,
  token: '',
  countOfTrips: 0
};

export const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setThemeMode: (state: any, action: any) => {
      state.themeMode = action.payload;
    },
    setToken: (state: any, action: any) => {
      state.token = action.payload;
    },
    setCountOfTrips: (state: any, action: any) => {
      state.countOfTrips = action.payload;
    },
  },
});

export const { setThemeMode, setToken } = appReducer.actions;

export const selectThemeMode = (state: RootState): ThemeMode => state.app.themeMode;
export const selectToken = (state: RootState): string => state.app.token;
export const selectCountOfTrips = (state: RootState): number => state.app.countOfTrips;

export default appReducer.reducer;

import { TextField } from "@mui/material";

const SelectBase = (props: any) => {
  const { list = [], meta = {}, helperText } = props;
  const { error = "", touched = false } = meta;
  const isError = Boolean(touched && error);
  return (
    <TextField
      {...props}
      select
      SelectProps={{ native: true }}
      error={isError}
      helperText={error || helperText}
    >
      {list.length && <option key={"-"} value={undefined}></option>}
      {list.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
    // <Select
    //   {...props}
    //   // select
    //   // SelectProps={{ native: true }}
    //   // error={isError}
    //   // helperText={error || helperText}
    // >
    //   {/* <MyListSubheader>Group 1</MyListSubheader> */}

    //   {list.map((option: any) => (
    //     <MenuItem value={option.value}> {option.label}</MenuItem>
    //   ))}
    // </Select>
  );
};
const renderSelect = ({ input: { onChange, ...restInput }, ...props }: any) => {
  return <SelectBase onChange={onChange} {...props} {...restInput} />;
};

export { SelectBase };
export default renderSelect;

import { Box, Link, Container, Stack, Typography, Avatar } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TikTokIcon from "@mui/icons-material/MusicNote";
import EmailIcon from "@mui/icons-material/Email";

const DiliInfoPage = () => (
  <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack spacing={3}>
          <Box display="flex" alignItems="center">
            <Avatar
              src="/di-logo.jpg"
              variant="square"
              sx={(theme) => ({
                height: theme.typography.h1.fontSize,
                width: theme.typography.h1.fontSize,
                marginRight: theme.spacing(1),
              })}
            />

            <Typography variant="h2" color="primary.contrastText">
              DILI INFO
            </Typography>
          </Box>
          Приложение с информацией о городе, находится в стадии разработки.
          <br />
          Запуск планируется в ближайшее время
          <Box textAlign="center">Наши социальные сети:</Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Link href="https://vk.com/diliinfo" color="inherit" sx={{ mr: 2 }}>
              VK
            </Link>
            <Link
              href="https://www.facebook.com/dili.info.dl/"
              color="inherit"
              sx={{ mr: 2 }}
            >
              <FacebookIcon />
            </Link>
            <Link
              href="https://www.tiktok.com/@dili.info"
              color="inherit"
              sx={{ mr: 2 }}
            >
              <TikTokIcon />
            </Link>
            <Link href="mailto:dili.info@dilijanlogistics.com" color="inherit">
              <EmailIcon />
            </Link>{" "}
          </Box>
        </Stack>
      </Container>
    </Box>
  </>
);

export default DiliInfoPage;

import LogoutIcon from "@mui/icons-material/Logout";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import HistoryIcon from "@mui/icons-material/History";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TourIcon from "@mui/icons-material/Tour";
import TransferPage from "../pages/TransferPage";
import ToursPage from "../pages/ToursPage";
import MovingPage from "../pages/MovingPage";
import TaxiPage from "../pages/TaxiPage";
import TripsPage from "../pages/TripsPage";
import LogoutPage from "../pages/LogoutPage";
import CarsPage from "../pages/CarsPage";
import HistoryPage from "../pages/HistoryPage";
import PolicyPage from "../pages/PolicyPage";
import ContactsPage from "../pages/ContactsPage";
import PaymentPage from "../pages/PaymentPage";
import TourRequest from "../tour/tour-request";

export const footerMenuItems = [
  {
    title: "Policy",
    path: "/policy",
    component: <PolicyPage />,
  },
  {
    title: "Контакты",
    path: "/contacts",
    component: <ContactsPage />,
  },
];

export const menuItems = [
  {
    title: "Экскурсии",
    path: "/tour",
    icon: <TourIcon />,
    component: <ToursPage />,
  },
  // {
  //   title: "Карточка экскурсий",
  //   closed: true,
  //   path: "/tours/:tourId",
  //   icon: <TourIcon />,
  //   component: <TourRequest />,
  // },
  {
    title: "Трансфер",
    path: "/transfer",
    icon: <DirectionsCarIcon />,
    component: <TransferPage />,
  },
  {
    title: "Грузоперевозки",
    path: "/moving",
    icon: <LocalShippingIcon />,
    component: <MovingPage />,
  },
  {
    title: "Vato taxi",
    path: "/taxi",
    icon: <LocalTaxiIcon />,
    component: <TaxiPage />,
  },
  {
    title: "Рейсы",
    path: "/trips",
    closed: true,
    icon: <EditRoadIcon />,
    component: <TripsPage />,
  },
  {
    title: "История рейсов",
    path: "/history",
    closed: true,
    icon: <HistoryIcon />,
    component: <HistoryPage />,
  },
  {
    title: "Автопарк",
    path: "/cars",
    closed: true,
    icon: <DirectionsCarIcon />,
    component: <CarsPage />,
  },
  {
    title: "Выйти",
    path: "/logout",
    closed: true,
    icon: <LogoutIcon />,
    component: <LogoutPage />,
  },
];

import { TripType } from '../enums/TripType';

interface CalculateTotal {
  tripType: TripType;
  numberOfBookedSeats: number;
  priceOfLuggages: number;
  priceOfTrip: number;
  priceOnlyLuggages: number;
  numberOfBookedLuggages: number;
}
interface CalculateLuggage {
  numberOfBookedSeats: number;
  priceOfLuggages: number;
  priceOnlyLuggages: number;
  numberOfBookedLuggages: number;
}
export const calculateLuggage = ({
  numberOfBookedSeats,
  priceOfLuggages,
  priceOnlyLuggages,
  numberOfBookedLuggages,
}: CalculateLuggage): number => {
  return numberOfBookedSeats > 0
    ? numberOfBookedLuggages * priceOfLuggages
    : numberOfBookedLuggages * priceOnlyLuggages;
};

export const calculateTotal = ({
  tripType,
  numberOfBookedSeats,
  priceOfLuggages,
  priceOfTrip,
  priceOnlyLuggages,
  numberOfBookedLuggages,
}: CalculateTotal): number => {
  if (tripType === TripType.INDIVIDUAL_CAR) {
    return priceOfTrip;
  }
  return (
    +numberOfBookedSeats * priceOfTrip +
    calculateLuggage({
      numberOfBookedSeats,
      priceOfLuggages,
      priceOnlyLuggages,
      numberOfBookedLuggages,
    })
  );
};

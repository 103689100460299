import { createSlice } from "@reduxjs/toolkit";
import { City } from "../sdk/interfaces";
import { RootState } from "../store";

interface cityState {
  cities: City[];
}

const initialState: cityState = {
  cities: [],
};

export const cityReducer = createSlice({
  name: "city",
  initialState,
  reducers: {
    setCities: (state, action) => {
      state.cities = action.payload;
    },
  },
});

export const { setCities } = cityReducer.actions;

export const selectCities = (state: RootState): City[] => state.city.cities;

export default cityReducer.reducer;

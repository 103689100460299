import { createSlice } from '@reduxjs/toolkit';
import { Trip } from '../sdk/interfaces';
import { RootState } from '../store';

interface TripState {
  activeTrips: Trip[];
  historyTrips: Trip[];
}

const initialState: TripState = {
  activeTrips: [],
  historyTrips: [],
};

export const tripReducer = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    setActiveTrips: (state, action) => {
      state.activeTrips = action.payload;
    },
    setHistoryTrips: (state, action) => {
      state.historyTrips = action.payload;
    },
  },
});

export const { setActiveTrips, setHistoryTrips } = tripReducer.actions;

export const selectActiveTrips = (state: RootState): Trip[] => state.trip.activeTrips;
export const selectHistoryTrips = (state: RootState): Trip[] => state.trip.historyTrips;

export default tripReducer.reducer;

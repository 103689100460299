import { Box, Container, Stack, Typography } from "@mui/material";

const PolicyPage = () => (
  <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Typography variant="h4">Policy</Typography>
        </Stack>
        <p>
          Dilijan Logistic’s Privacy Policy
          <br />
          Last updated: April 21, 2023
          <br />
          This Privacy Policy describes Our policies and procedures on the
          collection, use and
          <br />
          disclosure of Your information when You use the Service and tells You
          about Your privacy
          <br />
          rights and how the law protects You.
          <br />
          We use Your Personal data to provide and improve the Service. By using
          the Service, You
          <br />
          agree to the collection and use of information in accordance with this
          Privacy Policy.
          <br />
          Interpretation and Definitions
          <br />
          Interpretation
          <br />
          The words of which the initial letter is capitalized have meanings
          defined under the
          <br />
          following conditions. The following definitions shall have the same
          meaning regardless of
          <br />
          whether they appear in singular or in plural.
          <br />
          Definitions
          <br />
          For the purposes of this Privacy Policy:
          <br />
          Account means a unique account created for You to access our Service
          or parts of
          <br />
          our Service.
          <br />
          Affiliate means an entity that controls, is controlled by or is under
          common control
          <br />
          with a party, where "control" means ownership of 50% or more of the
          shares, equity
          <br />
          interest or other securities entitled to vote for election of
          directors or other
          <br />
          managing authority.
          <br />
          Application refers to Vato Taxi, the software program provided by the
          Company.
          <br />
          Company (referred to as either "the Company", "We", "Us" or "Our" in
          this
          <br />
          Agreement) refers to Dilijan Logistics, registered by IP Mhitaryan
          Georg Arsenovich,
          <br />
          GAI street 82-36.
          <br />
          Country refers to: Armenia
          <br />
          Device means any device that can access the Service such as a
          computer, a<br />
          cellphone or a digital tablet.
          <br />
          Personal Data is any information that relates to an identified or
          identifiable
          <br />
          individual.
          <br />
          Service refers to the Application.
          <br />
          Service Provider means any natural or legal person who processes the
          data on
          <br />
          behalf of the Company. It refers to third-party companies or
          individuals employed
          <br />
          by the Company to facilitate the Service, to provide the Service on
          behalf of the
          <br />
          Company, to perform services related to the Service or to assist the
          Company in
          <br />
          analyzing how the Service is used.Usage Data refers to data collected
          automatically, either generated by the use of the
          <br />
          Service or from the Service infrastructure itself (for example, the
          duration of a page
          <br />
          visit).
          <br />
          You means the individual accessing or using the Service, or the
          company, or other
          <br />
          legal entity on behalf of which such individual is accessing or using
          the Service, as
          <br />
          applicable.
          <br />
          Collecting and Using Your Personal Data
          <br />
          Types of Data Collected
          <br />
          Personal Data
          <br />
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable
          <br />
          information that can be used to contact or identify You. Personally
          identifiable information
          <br />
          may include, but is not limited to:
          <br />
          Phone number
          <br />
          Address, State, Province, City
          <br />
          Usage Data
          <br />
          Usage Data
          <br />
          Usage Data is collected automatically when using the Service.
          <br />
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP
          <br />
          address), browser type, browser version, the pages of our Service that
          You visit, the time
          <br />
          and date of Your visit, the time spent on those pages, unique device
          identifiers and other
          <br />
          diagnostic data.
          <br />
          When You access the Service by or through a mobile device, We may
          collect certain
          <br />
          information automatically, including, but not limited to, the type of
          mobile device You use,
          <br />
          Your mobile device unique ID, the IP address of Your mobile device,
          Your mobile operating
          <br />
          system, the type of mobile Internet browser You use, unique device
          identifiers and other
          <br />
          diagnostic data.
          <br />
          We may also collect information that Your browser sends whenever You
          visit our Service or
          <br />
          when You access the Service by or through a mobile device.
          <br />
          Use of Your Personal Data
          <br />
          The Company may use Personal Data for the following purposes:
          <br />
          To provide and maintain our Service, including to monitor the usage of
          our
          <br />
          Service.To manage Your Account: to manage Your registration as a user
          of the Service. The
          <br />
          Personal Data You provide can give You access to different
          functionalities of the
          <br />
          Service that are available to You as a registered user.
          <br />
          For the performance of a contract: the development, compliance and
          undertaking
          <br />
          of the purchase contract for the products, items or services You have
          purchased or
          <br />
          of any other contract with Us through the Service.
          <br />
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent
          <br />
          forms of electronic communication, such as a mobile application's push
          notifications
          <br />
          regarding updates or informative communications related to the
          functionalities,
          <br />
          products or contracted services, including the security updates, when
          necessary or
          <br />
          reasonable for their implementation.
          <br />
          To provide You with news, special offers and general information about
          other
          <br />
          goods, services and events which we offer that are similar to those
          that you have
          <br />
          already purchased or enquired about unless You have opted not to
          receive such
          <br />
          information.
          <br />
          To manage Your requests: To attend and manage Your requests to Us.
          <br />
          For business transfers: We may use Your information to evaluate or
          conduct a<br />
          merger, divestiture, restructuring, reorganization, dissolution, or
          other sale or
          <br />
          transfer of some or all of Our assets, whether as a going concern or
          as part of
          <br />
          bankruptcy, liquidation, or similar proceeding, in which Personal Data
          held by Us
          <br />
          about our Service users is among the assets transferred.
          <br />
          For other purposes: We may use Your information for other purposes,
          such as data
          <br />
          analysis, identifying usage trends, determining the effectiveness of
          our promotional
          <br />
          campaigns and to evaluate and improve our Service, products, services,
          marketing
          <br />
          and your experience.
          <br />
          We may share Your personal information in the following situations:
          <br />
          With Service Providers: We may share Your personal information with
          Service
          <br />
          Providers to monitor and analyze the use of our Service, to contact
          You.
          <br />
          For business transfers: We may share or transfer Your personal
          information in
          <br />
          connection with, or during negotiations of, any merger, sale of
          Company assets,
          <br />
          financing, or acquisition of all or a portion of Our business to
          another company.
          <br />
          With Affiliates: We may share Your information with Our affiliates, in
          which case we
          <br />
          will require those affiliates to honor this Privacy Policy. Affiliates
          include Our parent
          <br />
          company and any other subsidiaries, joint venture partners or other
          companies that We
          <br />
          control or that are under common control with Us.
          <br />
          With business partners: We may share Your information with Our
          business partners
          <br />
          to offer You certain products, services or promotions.
          <br />
          With other users: when You share personal information or otherwise
          interact in the
          <br />
          public areas with other users, such information may be viewed by all
          users and may be
          <br />
          publicly distributed outside.
          <br />
          With Your consent: We may disclose Your personal information for any
          other purpose
          <br />
          with Your consent.The app does use third-party services that may
          collect information used to identify you.
          <br />
          Link to the privacy policy of third-party service providers used by
          the app
          <br />
          • Google Play Services
          <br />
          • Expo
          <br />
          Retention of Your Personal Data
          <br />
          The Company will retain Your Personal Data only for as long as is
          necessary for the
          <br />
          purposes set out in this Privacy Policy. We will retain and use Your
          Personal Data to the
          <br />
          extent necessary to comply with our legal obligations (for example, if
          we are required to
          <br />
          retain your data to comply with applicable laws), resolve disputes,
          and enforce our legal
          <br />
          agreements and policies.
          <br />
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is
          <br />
          generally retained for a shorter period of time, except when this data
          is used to strengthen
          <br />
          the security or to improve the functionality of Our Service, or We are
          legally obligated to
          <br />
          retain this data for longer time periods.
          <br />
          Transfer of Your Personal Data
          <br />
          Your information, including Personal Data, is processed at the
          Company's operating offices
          <br />
          and in any other places where the parties involved in the processing
          are located. It means
          <br />
          that this information may be transferred to — and maintained on —
          computers located
          <br />
          outside of Your state, province, country or other governmental
          jurisdiction where the data
          <br />
          protection laws may differ than those from Your jurisdiction.
          <br />
          Your consent to this Privacy Policy followed by Your submission of
          such information
          <br />
          represents Your agreement to that transfer.
          <br />
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated
          <br />
          securely and in accordance with this Privacy Policy and no transfer of
          Your Personal Data
          <br />
          will take place to an organization or a country unless there are
          adequate controls in place
          <br />
          including the security of Your data and other personal information.
          <br />
          Delete Your Personal Data
          <br />
          You have the right to delete or request that We assist in deleting the
          Personal Data that We
          <br />
          have collected about You.
          <br />
          Our Service may give You the ability to delete certain information
          about You from within
          <br />
          the Service.
          <br />
          You may update, amend, or delete Your information at any time by
          signing in to Your
          <br />
          Account, if you have one, and visiting the account settings section
          that allows you to manage
          <br />
          Your personal information. You may also contact Us via
          www.DilijanLogistics.com/revoke
          <br />
          to request access to, correct, or delete any personal information that
          You have provided to
          <br />
          Us.Please note, however, that We may need to retain certain
          information when we have a legal
          <br />
          obligation or lawful basis to do so.
          <br />
          Disclosure of Your Personal Data
          <br />
          Business Transactions
          <br />
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be
          <br />
          transferred. We will provide notice before Your Personal Data is
          transferred and becomes
          <br />
          subject to a different Privacy Policy.
          <br />
          Law enforcement
          <br />
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data
          <br />
          if required to do so by law or in response to valid requests by public
          authorities (e.g. a court
          <br />
          or a government agency).
          <br />
          Other legal requirements
          <br />
          The Company may disclose Your Personal Data in the good faith belief
          that such action is
          <br />
          necessary to:
          <br />
          Comply with a legal obligation
          <br />
          Protect and defend the rights or property of the Company
          <br />
          Prevent or investigate possible wrongdoing in connection with the
          Service
          <br />
          Protect the personal safety of Users of the Service or the public
          <br />
          Protect against legal liability
          <br />
          Security of Your Personal Data
          <br />
          The security of Your Personal Data is important to Us, but remember
          that no method of
          <br />
          transmission over the Internet, or method of electronic storage is
          100% secure. While We
          <br />
          strive to use commercially acceptable means to protect Your Personal
          Data, We cannot
          <br />
          guarantee its absolute security.
          <br />
          Children's Privacy
          <br />
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect
          <br />
          personally identifiable information from anyone under the age of 13.
          If You are a parent or
          <br />
          guardian and You are aware that Your child has provided Us with
          Personal Data, please
          <br />
          contact Us. If We become aware that We have collected Personal Data
          from anyone under
          <br />
          the age of 13 without verification of parental consent, We take steps
          to remove that
          <br />
          information from Our servers.
          <br />
          If We need to rely on consent as a legal basis for processing Your
          information and Your
          <br />
          country requires consent from a parent, We may require Your parent's
          consent before We
          <br />
          collect and use that information.Links to Other Websites
          <br />
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a<br />
          third party link, You will be directed to that third party's site. We
          strongly advise You to
          <br />
          review the Privacy Policy of every site You visit.
          <br />
          We have no control over and assume no responsibility for the content,
          privacy policies or
          <br />
          practices of any third party sites or services.
          <br />
          Changes to this Privacy Policy
          <br />
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by
          <br />
          posting the new Privacy Policy on this page.
          <br />
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the
          <br />
          change becoming effective and update the "Last updated" date at the
          top of this Privacy
          <br />
          Policy.
          <br />
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this
          <br />
          Privacy Policy are effective when they are posted on this page.
          <br />
          Contact Us
          <br />
          If you have any questions about this Privacy Policy, You can contact
          us:
          <br />
          •<br />
          By visiting this page on our website: www.dilijanLogistics.com/contact
        </p>
      </Container>
    </Box>
  </>
);

export default PolicyPage;

import { useEffect, useState } from "react";
import { Box, Container, Card, CardContent } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import PaymentSuccess from "../payment/PaymentSuccess";
import PaymentFailed from "../payment/PaymentFailed";
import { Client } from "../../enums/Client";
import { CallbackClient } from "../../enums/Callback";
import { AmeriaQueryParams } from "../../sdk/interfaces/payment";
import { api } from "../../sdk";

const PaymentPage = () => {
  const { client } = useParams<{ client: string }>();
  const { search } = useLocation();
  const paymentData: AmeriaQueryParams = Object.fromEntries(
    new URLSearchParams(search)
  );
  const [errorMessage, setErrorMessage] = useState("");
  const savePaymentResult = async () => {
    setErrorMessage("");
    try {
      await api.payment.savePayment({
        paymentID: paymentData.paymentID,
      });
    } catch (error: any) {
      return setErrorMessage(error.message);
    }
  };

  const handleReturnToApp = async () => {
    // if (client === Client.MP) {
    //   window.location.href = CallbackClient.MP;
    // }
    if (client === Client.MD) {
      window.location.href = CallbackClient.MPD;
    }
    if (client === Client.WS) {
      window.location.href = CallbackClient.WEB;
    }
  };
  useEffect(() => {
    savePaymentResult();
  }, []);

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }} textAlign="center" mt={3}>
      <Container maxWidth="lg">
        <Card>
          <CardContent>
            <Box component="h4" fontSize={24} fontWeight="bold">
              Результат оплаты
            </Box>
            {paymentData.resposneCode === "00" ? (
              <PaymentSuccess handleReturnToApp={handleReturnToApp} />
            ) : (
              <PaymentFailed handleReturnToApp={handleReturnToApp} />
            )}
          </CardContent>
          {errorMessage}
        </Card>
      </Container>
    </Box>
  );
};

export default PaymentPage;

import { Destination } from "../interfaces";
import { AxiosConnector } from "./axiosConnector";

export class DestinationApi extends AxiosConnector {
  async list(): Promise<Destination[]> {
    const result = await this.axios
      .get<Destination[]>(`${this.uri}destination`)
      .catch<any>(this.handleError);
    const { data } = result;
    return data;
  }

  async getById( id: number ): Promise<Destination[]>  {
    const result = await this.axios
      .get<Destination[]>(`${this.uri}destination/${id}`)
      .catch<any>(this.handleError);
    const { data } = result;
    return data;
  }
}

import { TextField } from "@mui/material";

const Input = (props: any) => {
  const { meta } = props;
  const { error, touched } = meta;
  const isError = Boolean(touched && error);
  return <TextField {...props} error={isError} helperText={error} />;
};

const renderInput = ({ input: { onChange, ...restInput }, ...props }: any) => {
  return <Input onChange={onChange} {...restInput} {...props} />;
};

export { Input };

export default renderInput;

import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
  CircularProgress,
  Box,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDestinations,
  setDestinations,
} from "../../reducers/destination";
import { TripType } from "../../enums/TripType";
import { api } from "../../sdk";
import { OrderCreateForm } from "../../reducers/order";
import { LENGTH_ARMENIAN_NUMBER_WITHOUT_CODE } from "../../utils/constants";
import { Field, getFormValues, reduxForm, reset } from "redux-form";
import renderInput from "../html/renderInput";
import renderSelect from "../html/renderSelect";
import { normalizePhone } from "../../utils/validation";
import NoTrips from "../trip/NoTrips";
import { generateDropDown } from "../../utils/fakeData";
import { Destination } from "../../sdk/interfaces";

const states = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
];
const TransferRequest = ({ handleSubmit }: { handleSubmit: any }) => {
  const [isDestinationRefreshing, setIsDestinationRefreshing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const destinations: Destination[] = useSelector(
    selectDestinations(TripType.INDIVIDUAL_CAR)
  );
  const dispatch = useDispatch();
  const orderCreate = useSelector<any, any>(getFormValues("orderCreate"));
  const destination =
    destinations.find(
      (d: Destination) => d.id === +orderCreate?.idDestination
    ) || destinations[0];

  const [orderAccepted, setOrderAccepted] = useState(false);

  const submit = async (values: OrderCreateForm) => {
    setErrorMessage("");
    try {
      await api.order.create({
        ...values,
        from: { ...values.from, id: destination.from.id },
        to: { ...values.to, id: destination.to.id },
        idDestination: destination.id,
        type: destination.type,
      });
      setOrderAccepted(true);
      dispatch(reset("orderCreate"));
    } catch (error: any) {
      return setErrorMessage(error.message);
    }
  };

  const uploadDestinations = () => {
    setIsDestinationRefreshing(true);
    api.destination
      .list()
      .then((list: any) => {
        dispatch(setDestinations(list));
        setIsDestinationRefreshing(false);
      })
      .catch((err: any) => {
        setIsDestinationRefreshing(false);
      });
  };

  useEffect(() => {
    uploadDestinations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDestinationRefreshing) {
    return <CircularProgress color="secondary" />;
  }

  const isDateSet = orderCreate?.date !== undefined;
  const isTimeSet = orderCreate?.time !== undefined;

  const thisMoment = new Date();

  const isToday =
    new Date(orderCreate?.date).toLocaleDateString() ===
    thisMoment.toLocaleDateString();
  const availableTimes = (() => {
    if (isToday) {
      return destination?.times.filter((times) => {
        const [h, t] = times.split(":");
        const templateTime = +h * 60 + +t;
        const thisTime = thisMoment.getHours() * 60 + thisMoment.getMinutes();
        return templateTime > thisTime;
      });
    }
    return destination?.times || [];
  })();

  return (
    <Card>
      <CardHeader title="Маршрут" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid xs={12} md={12}>
            <Field
              fullWidth
              helperText='Если у вас сложный маршрут, укажите остановки ниже в поле "Ваши
                  пожелания"'
              name="idDestination"
              component={renderSelect}
              placeholder="Направление"
              label="Направление"
              list={
                destinations
                  ? destinations.map((option) => ({
                      value: option.id,
                      label: `${option.from.name} -> ${option.to.name}`,
                    }))
                  : []
              }
              required
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Field
              name="date"
              component={renderSelect}
              label={"Дата поездки"}
              list={destination?.dates || []}
              required
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            {availableTimes.length > 0 ? (
              isDateSet ? (
                <Field
                  name="time"
                  component={renderSelect}
                  label={"Время отправления"}
                  list={generateDropDown(availableTimes)}
                  fullWidth
                  required
                />
              ) : null
            ) : (
              <NoTrips />
            )}
          </Grid>
          {isTimeSet ? (
            <>
              <Grid xs={12} md={6}>
                <Field
                  name="from.address"
                  component={renderInput}
                  label={`Откуда забрать (${destination.from.name})`}
                  fullWidth
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Field
                  fullWidth
                  name="to.address"
                  component={renderInput}
                  label={`Куда привезти (${destination.to.name})`}
                  required
                />
              </Grid>
            </>
          ) : null}

          {/* <Grid xs={12} md={6}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Туда и обратно"
              />
            </Grid> */}
        </Grid>
      </CardContent>
      {isTimeSet ? (
        <>
          <Divider />
          <CardHeader title="Машина" />
          <CardContent sx={{ pt: 0 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <Field
                  fullWidth
                  name="numberOfBookedSeats"
                  component={renderSelect}
                  label={"Количество мест"}
                  list={states}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Field
                  fullWidth
                  name="numberOfBookedCarSeats"
                  helperText="При выборе мест с детским креслом для них также необходимо выбрать места и в поле
                Количество мест"
                  component={renderSelect}
                  label={"Количество детских кресел"}
                  list={states}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Field
                  name="numberOfBookedLuggages"
                  component={renderSelect}
                  fullWidth
                  label={"Количество багажа"}
                  list={states}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardHeader title="Дополнительно" />
          <CardContent sx={{ pt: 0 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={12}>
                <Field
                  fullWidth
                  name="phone"
                  component={renderInput}
                  label={"Контактный номер"}
                  required
                  maxLength={LENGTH_ARMENIAN_NUMBER_WITHOUT_CODE}
                  normalize={normalizePhone}
                />
              </Grid>
              <Grid xs={12} md={12}>
                <Field
                  fullWidth
                  name="comment"
                  component={renderInput}
                  label="Ваши пожелания"
                  multiline
                  minRows={4}
                />
              </Grid>
            </Grid>
          </CardContent>
          {errorMessage}
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button variant="contained" onClick={handleSubmit(submit)}>
              Отправить
            </Button>
          </CardActions>
        </>
      ) : null}
      {orderAccepted && (
        <CardContent>
          <Chip
            label={
              <>
                Ваш заказ принят!
                <br />
                Диспетчер свяжется с вами в ближайшее время, для подтверждения
                данных.
              </>
            }
            color="success"
          />
        </CardContent>
      )}
    </Card>
  );
};

const validate = (values: OrderCreateForm) => {
  const errors = {} as any;
  if (values) {
    if (values.date === "") {
      errors.date = "Поле обязательно к заполнению";
    }
    if (values.from?.address === "") {
      errors.from = { address: "Поле обязательно к заполнению" };
    }
    if (values.to?.address === "") {
      errors.to = { address: "Поле обязательно к заполнению" };
    }
    if (values.time === "") {
      errors.time = "Поле обязательно к заполнению";
    }
    if (
      values.phone &&
      values.phone.length !== LENGTH_ARMENIAN_NUMBER_WITHOUT_CODE
    ) {
      errors.phone = "Неверный номер";
    }
    if (values.numberOfBookedSeats === undefined) {
      errors.numberOfBookedSeats = "Поле обязательно к заполнению";
    }
    if (values.numberOfBookedCarSeats === undefined) {
      errors.numberOfBookedCarSeats = "Поле обязательно к заполнению";
    } else {
      if (+values.numberOfBookedCarSeats > +values.numberOfBookedSeats) {
        errors.numberOfBookedCarSeats =
          "Детских кресел больше чем заказанных мест";
      }
    }
    if (values.numberOfBookedLuggages === undefined) {
      errors.numberOfBookedLuggages = "Поле обязательно к заполнению";
    }
  }
  return errors;
};

export default reduxForm({
  form: "orderCreate",
  validate,
})(TransferRequest);

import { Box, Link, Container, Stack, Typography, Avatar } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TikTokIcon from "@mui/icons-material/MusicNote";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";

const TaxiPage = () => (
  <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack spacing={3}>
          <Box display="flex" alignItems="center">
            <Avatar
              src="/logo.jpg"
              variant="square"
              sx={(theme) => ({
                height: theme.typography.h1.fontSize,
                width: theme.typography.h1.fontSize,
                marginRight: theme.spacing(1),
              })}
            />

            <Typography variant="h2" color="primary.contrastText">
              VATO TAXI
            </Typography>
          </Box>
          Для заказа машины воспользуйтесь приложением:
          <Box sx={{ display: "flex", mt: 2 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "310px",
              }}
            >
              <a href="https://play.google.com/store/search?q=vato%20taxi&c=apps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  width="140px"
                  height="58px"
                  alt="Доступно в Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/ru_badge_web_generic.png"
                />
              </a>
              <a
                href="https://apple.co/46VC47J"
                style={{
                  overflow: "hidden",
                  borderRadius: "13px",
                  width: "150px",
                  height: "58px",
                }}
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ru-ru?size=250x83&amp;releaseDate=1689897600"
                  alt="Download on the App Store"
                  style={{
                    borderRadius: "13px",
                    width: "150px",
                    height: "58px",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                  }}
                />
              </a>
            </div>
          </Box>
          Наши социальные сети:
          <Box sx={{ display: "flex", mt: 2 }}>
            <Link
              href="https://vk.com/vato.taxi"
              color="inherit"
              sx={{ mr: 2 }}
            >
              VK
            </Link>
            <Link
              href="https://www.facebook.com/vato.taxi.dl"
              color="inherit"
              sx={{ mr: 2 }}
            >
              <FacebookIcon />
            </Link>
            <Link href="https://www.tiktok.com/@vato.taxi.dl" color="inherit">
              <TikTokIcon />
            </Link>{" "}
            <Link
              href="https://t.me/+BLPs7VhSLnIyODFi"
              color="inherit"
              sx={{ mr: 2 }}
            >
              <TelegramIcon />
            </Link>
            <Link href="mailto:vato.taxi@dilijanlogistics.com" color="inherit">
              <EmailIcon />
            </Link>
          </Box>
        </Stack>
      </Container>
    </Box>
  </>
);

export default TaxiPage;

import PersonIcon from "@mui/icons-material/Person";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import LuggageIcon from "@mui/icons-material/Luggage";
import { Chip } from "@mui/material";

const Seats = ({
  numberOfBookedSeats,
  numberOfBookedCarSeats,
  numberOfBookedLuggages,
}: {
  numberOfBookedSeats: number;
  numberOfBookedCarSeats: number;
  numberOfBookedLuggages: number;
}) => {
  return (
    <>
      {numberOfBookedSeats > 0 && (
        <Chip icon={<PersonIcon />} label={`${numberOfBookedSeats}`} />
      )}
      {numberOfBookedCarSeats > 0 && (
        <Chip
          icon={<AirlineSeatReclineNormalIcon />}
          label={`${numberOfBookedCarSeats}`}
        />
      )}
      {numberOfBookedLuggages > 0 && (
        <Chip icon={<LuggageIcon />} label={`${numberOfBookedLuggages}`} />
      )}
    </>
  );
};

export default Seats;

import { createSlice } from "@reduxjs/toolkit";
import { Destination } from "../sdk/interfaces";
import { RootState } from "../store";
import { TripType } from "../enums/TripType";
import { readableDateTime } from "../utils/date";

interface TourState {
  tours: Destination[];
}

const initialState: TourState = {
  tours: [],
};

export const tourReducer = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setTour: (state, action) => {
      state.tours = action.payload;
    },
  },
});

export const { setTour } = tourReducer.actions;

export const selectTour = (type: TripType) => {
  return (state: RootState): Destination[] => {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);
    const inXDays = new Date();
    inXDays.setUTCHours(0, 0, 0, 0);
    inXDays.setDate(inXDays.getDate() + 10);
    return state.tour.tours
      .filter((d: any) => d.type === type)
      .map((d: any) => ({
        ...d,
        dates: d.dates
          .filter((date: any) => {
            const checkedDate = new Date(date);
            return currentDate <= checkedDate && inXDays > checkedDate;
          })
          .map((value: any) => {
            return {
              label: readableDateTime(`${value}`, false),
              value,
            } as any;
          }),
      }));
  };
};
export default tourReducer.reducer;

import { Box, Link, Container, Stack, Typography, Avatar } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TikTokIcon from "@mui/icons-material/MusicNote";
import TelegramIcon from "@mui/icons-material/Telegram";

const DiliDeliveryPage = () => (
  <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack spacing={3}>
          <Box display="flex" alignItems="center">
            <Avatar
              src="/dl-logo.jpg"
              variant="square"
              sx={(theme) => ({
                height: theme.typography.h1.fontSize,
                width: theme.typography.h1.fontSize,
                marginRight: theme.spacing(1),
              })}
            />

            <Typography variant="h2" color="primary.contrastText">
              DILI DELIVERY
            </Typography>
          </Box>
          Приложение для заказа доставки, находится в стадии разработки.
          <br />
          Запуск планируется в ближайшее время
        </Stack>
      </Container>
    </Box>
  </>
);

export default DiliDeliveryPage;

import { Routes, Route } from "react-router-dom";
import { Layout } from "./components/layout/layout";
import NotFoundPage from "./components/pages/NotFoundPage";
import { footerMenuItems, menuItems } from "./components/layout/menu-items";
import LoginPage from "./components/pages/LoginPage";
import TaxiPage from "./components/pages/TaxiPage";
import PaymentPage from "./components/pages/PaymentPage";
import DiliInfoPage from "./components/pages/DiliInfo";
import DiliDeliveryPage from "./components/pages/DilDelivery";
import RevokePage from "./components/pages/RevokePage";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<TaxiPage />} />
          {footerMenuItems.map((item) => (
            <Route path={item.path} element={item.component} key={item.path} />
          ))}
          {menuItems.map((item) => (
            <Route path={item.path} element={item.component} key={item.path} />
          ))}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dili-info" element={<DiliInfoPage />} />
          <Route path="/dili-delivery" element={<DiliDeliveryPage />} />
          <Route path="/callback/payment/:client" element={<PaymentPage />} />
          <Route path="/revoke" element={<RevokePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;

import { SelectBase } from "../html/renderSelect";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonIcon from "@mui/icons-material/Person";
import { Car, Trip } from "../../sdk/interfaces";
import { AMD } from "../../utils/constants";
import { calculateTotal } from "../../utils/price";
import { api } from "../../sdk";
import { useState } from "react";
import Seats from "../order/Seats";
import HelpIcon from "@mui/icons-material/Help";
import MovingIcon from "@mui/icons-material/LocalShipping";
import TourIcon from "@mui/icons-material/Tour";
import { tripStatusCompleteSet } from "../../utils/statusMap";
import { TripStatus } from "../../enums/TripStatus";
import TripStatusChip from "./TripStatusChip";
import { TripType } from "../../enums/TripType";
import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import TripCard from "./TripCard";
import { useLocation } from "react-router-dom";
import { Color } from "../../type/color";

const TripRow = ({
  tripDefault,
  cars,
}: {
  tripDefault: Trip;
  cars: Car[];
  key: number;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trip, setTrip] = useState<Trip | undefined>(tripDefault);

  const [isTripRefreshing, setIsTripRefreshing] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const isEditing = pathname === "/trips";
  const [idCar, setIdCar] = useState(trip?.car?.id || null);
  if (!trip) {
    return <>"Ошибка при загрузке поездки"</>;
  }
  const getTripTypeChip = (tripType: TripType) => {
    let icon;
    let label;
    let color: Color;

    switch (tripType) {
      case TripType.INDIVIDUAL_CAR:
        icon = <DirectionsCarIcon />;
        label = "Индивидуальный";
        color = "info";
        break;
      case TripType.TAXI_SEAT:
        icon = <PersonIcon />;
        label = "Шаттл";
        color = "default";
        break;
      case TripType.MOVING:
        icon = <MovingIcon />;
        label = "Грузоперевозка";
        color = "secondary";
        break;
      case TripType.TOUR:
        icon = <TourIcon />;
        label = "Экскурсия";
        color = "primary";
        break;
      default:
        icon = <HelpIcon />;
        label = "Не указан";
        color = "error";
    }

    return (
      <Chip icon={icon} label={label} color={color} style={{ width: "100%" }} />
    );
  };

  const StartTripButton = () =>
    trip.car && (
      <Button
        variant="contained"
        style={{ width: "100%" }}
        color="success"
        onClick={async () => {
          try {
            await api.trip.start({ idTrip: trip.id });
            await uploadTrip(trip.id);
          } catch (error: any) {
            setErrorMessage(error.message);
          }
        }}
      >
        Начать
      </Button>
    );

  const FinishTripButton = () => (
    <Button
      variant="contained"
      style={{ width: "100%" }}
      color="warning"
      onClick={async () => {
        try {
          await api.trip.finish({ idTrip: trip.id, amount: 0 });
          await uploadTrip(trip.id);
        } catch (error: any) {
          setErrorMessage(error.message);
        }
      }}
    >
      Завершить
    </Button>
  );

  const tripName = `${trip.from.name} -> ${trip.to.name}`;
  const updateCar = (newIdCar: any) => {
    api.trip
      .updateCar({
        id: trip.id,
        idCar: newIdCar,
      })
      .then(async () => {
        setIdCar(newIdCar);
        uploadTrip(trip.id);
      });
    // .catch(() => {
    //   setIsUpdateCar(false);
    // });
  };

  const uploadTrip = (id: number) => {
    setIsTripRefreshing(true);
    api.trip
      .list()
      .then((list: Trip[]) => {
        setTrip(list.find((t) => t.id === id));
        setIsTripRefreshing(false);
      })
      .catch((err: Error) => {
        if (err.message === "Unauthorized request") {
          api.setToken("");
          // LOGOUT
        }
        setIsTripRefreshing(false);
      });
  };

  const percent = (+trip.numberOfBookedSeats / trip.numberOfSeats) * 100;
  type COLOR = "error" | "success" | "warning" | "inherit";
  let color: COLOR = "warning";
  if (percent < 20) {
    color = "inherit";
  }
  if (percent > 80) {
    color = "success";
  }
  if (percent > 100) {
    color = "error";
  }

  if (isTripRefreshing) {
    return <CircularProgress />;
  }

  return (
    <Grid item xs={12} sm={6} md={4} key={trip.id}>
      <Card>
        <LinearProgress
          variant="determinate"
          value={percent}
          color={color}
          style={{ marginBottom: 0, marginTop: 0, padding: 5 }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h5">
                    #{trip.id} {tripName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {calculateTotal({
                      tripType: trip.type,
                      numberOfBookedLuggages: trip.numberOfBookedLuggages,
                      numberOfBookedSeats: trip.numberOfBookedSeats,
                      priceOfLuggages: trip.priceOfLuggages,
                      priceOfTrip: trip.priceOfTrip,
                      priceOnlyLuggages: trip.priceOnlyLuggages,
                    })}
                    {AMD}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                {getTripTypeChip(trip.type)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                <TripStatusChip status={trip.status} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                <Seats
                  numberOfBookedCarSeats={trip.numberOfBookedCarSeats}
                  numberOfBookedLuggages={trip.numberOfBookedLuggages}
                  numberOfBookedSeats={trip.numberOfBookedSeats}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {isEditing ? (
                <>
                  <SelectBase
                    label={"Машина"}
                    fullWidth
                    list={[
                      ...cars.map((car) => {
                        return {
                          value: car.id,
                          label: `${car.model} ${car.licensePlate}`,
                        };
                      }),
                    ]}
                    onChange={(v: any) => updateCar(v.target.value)}
                    value={idCar}
                  />
                  {errorMessage}
                </>
              ) : trip.car ? (
                <>
                  {`${trip.car.model} ${trip.car.licensePlate} ${trip.car.driver.name} ${trip.car.driver.phone}`}
                </>
              ) : (
                <>Машина не назначена</>
              )}
            </Grid>
            {!tripStatusCompleteSet.has(trip.status) && (
              <>
                <Grid item xs={12} md={6}>
                  {trip.status !== TripStatus.CURRENT ? (
                    <FinishTripButton />
                  ) : (
                    <StartTripButton />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    style={{ width: "100%" }}
                    onClick={async () => {
                      try {
                        await api.trip.cancel(trip.id);
                        await uploadTrip(trip.id);
                      } catch (error: any) {
                        setErrorMessage(error.message);
                      }
                    }}
                  >
                    Отменить
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={12}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                {open ? "Закрыть" : "Открыть"} список пассажиров
              </IconButton>
            </Grid>
            <Grid item xs={12} md={12}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <TripCard trip={trip} />
              </Collapse>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TripRow;

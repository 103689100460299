import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { AuthProvider } from "./contexts/auth-context";
import { inputLabelClasses, outlinedInputClasses } from "@mui/material";

const palette = {
  primary: {
    main: "#ffcc33",
  },
  secondary: {
    main: "#663300",
  },
};

const theme = createTheme({
  palette,
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          color: palette.secondary.main,
          borderColor: palette.primary.main,
          borderStyle: "solid",
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: palette.secondary.main,
          marginLeft: "10px",
          marginRight: "10px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "32px 24px",
          "&:last-child": {
            paddingBottom: "32px",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          [`&.${inputLabelClasses.shrink}`]: {
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.secondary.main,
            },
          },
          [`&.${outlinedInputClasses.focused}`]: {
            backgroundColor: "transparent",
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.secondary.main,
            },
          },
        },
      },
    },
  },
  shape: {
    // borderRadius: 8,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

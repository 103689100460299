import { Chip } from "@mui/material";
import { OrderStatus } from "../../enums/OrderStatus";
import { orderStatusMap } from "../../utils/statusMap";

const OrderStatusChip = ({ status }: { status: OrderStatus }) => {
  return (
    <Chip
      label={orderStatusMap.get(status)?.message}
      color={orderStatusMap.get(status)?.color}
      variant="outlined"
      style={{ width: "100%" }}
    />
  );
};

export default OrderStatusChip;

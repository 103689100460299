import { Chip, Box , Button} from '@mui/material';

const PaymentSuccess = ({handleReturnToApp }: { handleReturnToApp: () => void }) => {
  return (
    <Box textAlign="center" mt={3}>
    <Chip label="Успешный платеж" color="success" />
    <Box mt={2}>
        <Button variant="contained" onClick={handleReturnToApp}>
          Вернуться в приложение
        </Button>
      </Box>
  </Box>
  );
};

export default PaymentSuccess;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../../sdk";

import { Trip, Order } from "../../sdk/interfaces";
import { selectActiveTrips } from "../../reducers/trip";
// import { tripStatusCompleteSet } from "../../utils/statusMap";
import {
  // Button,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import OrderStatusChip from "../order/OrderStatusChip";
import { orderStatusCancelSet } from "../../utils/statusMap";
import Seats from "../order/Seats";

const TripCard = ({ trip }: { trip: Trip }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orders, setOrders] = useState<Order[]>([]);

  const uploadOrders = () => {
    api.order.listByIdTrip(trip.id).then((list) => {
      setOrders(list);
    });
  };

  useEffect(() => {
    uploadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      {orders.length > 0 &&
        orders.map((order, index) => (
          <Card
            style={{ margin: 5, padding: 5, backgroundColor: "#D3D3D3" }}
            key={`tripCardOrder${order.id}`}
          >
            <Grid
              container
              key={order.id}
              spacing={3}
              style={
                orderStatusCancelSet.has(order.status)
                  ? {
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                    }
                  : {}
              }
            >
              <Grid item xs={12}>
                <Typography variant="h4" align="center" style={{ margin: 15 }}>
                  +374{order.phone}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                Откуда забрать: {order.from.address}
              </Grid>
              <Grid item xs={12} md={6}>
                Куда привезти: {order.to.address}
              </Grid>
              <Grid item xs={12} md={6}>
                <OrderStatusChip status={order.status} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Seats
                  numberOfBookedCarSeats={order.numberOfBookedCarSeats}
                  numberOfBookedLuggages={order.numberOfBookedLuggages}
                  numberOfBookedSeats={order.numberOfBookedSeats}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                Комментарий: {order.comment}
              </Grid>
              {/* <Grid item xs={2}>
    {pathname === "/trips" &&
      !orderStatusCompleteSet.has(order.status) && (
        <SelectBase
          fullWidth
          label={"Переместить"}
          list={trips
            .filter((t) => !tripStatusCompleteSet.has(t.status))
            .map((d) => ({
              value: d.id,
              label: `#${d.id} ${readableDateTime(d.date)}: ${
                d.numberOfBookedSeats
              }/${d.numberOfBookedCarSeats}/${d.numberOfBookedLuggages}`,
            }))}
          onChange={(chosenIdTrip: any) =>
            updateTrip(order.id, chosenIdTrip.target.value)
          }
          value={newIdTrip}
        />
      )}
  </Grid> */}
            </Grid>
          </Card>
        ))}
    </Grid>
  );
};

export default TripCard;

import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { TourSearch } from "../tour/tour-search";
import { api } from "../../sdk";
import { Destination } from "../../sdk/interfaces";
import { TourCard } from "../tour/tour-card";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchTerm } from "../../reducers/searchTour";
import { RootState } from "../../store";

const ToursPage = () => {
  const [tours, setTours] = useState<Destination[]>([]);
  const [filteredTours, setFilteredTours] = useState<Destination[]>([]);
  const [isTourRefreshing, setIsTourRefreshing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchTerm = useSelector(
    (state: RootState) => state.tourSearch.searchTerm
  );
  const uploadTour = () => {
    setIsTourRefreshing(true);
    api.destination
      .list()
      .then((list) => {
        const typeTour = list.filter((item) => item.type === "tour");
        setTours(typeTour);
        setFilteredTours(typeTour);
        setIsTourRefreshing(false);
      })
      .catch((err) => {
        setIsTourRefreshing(false);
      });
  };

  useEffect(() => {
    uploadTour();
  }, []);

  const handleTourClick = (tour: Destination) => {
    navigate(`/tours/${tour.id}`);
  };

  const handleSearchInputChange = (event: any) => {
    dispatch(setSearchTerm(event.target.value));
    filterTours(event.target.value);
  };
  const filterTours = (query: string) => {
    const filtered = tours.filter((tour) =>
      tour.from.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredTours(filtered);
  };

  if (isTourRefreshing) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <Typography variant="h4">Экскурсии</Typography>
            <Typography variant="h6">Раздел в разработке</Typography>
          </Stack>
        </Container>

        {/* <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Экскурсии</Typography>
              </Stack>
            </Stack>
            <TourSearch
              onInputChange={handleSearchInputChange}
              searchTerm={searchTerm}
            />
            <Grid container spacing={3}>
              {filteredTours.map((tour) => (
                <Grid xs={12} md={6} lg={4} key={tour.id}>
                  <div onClick={() => handleTourClick(tour)}>
                    <TourCard tour={tour} />
                  </div>
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination count={3} size="small" />
            </Box>
          </Stack>
        </Container> */}
      </Box>
    </>
  );
};

export default ToursPage;

import { Chip } from "@mui/material";
import { TripStatus } from "../../enums/TripStatus";
import { tripStatusMap } from "../../utils/statusMap";

const TripStatusChip = ({ status }: { status: TripStatus }) => {
  return (
    <Chip
      label={tripStatusMap.get(status)?.message}
      color={tripStatusMap.get(status)?.color}
      variant="filled"
      style={{ width: "100%" }}
    />
  );
};

export default TripStatusChip;

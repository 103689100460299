import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
  CircularProgress,
  Box,
  Chip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TripType } from "../../enums/TripType";
import { api } from "../../sdk";
import { OrderCreateForm } from "../../reducers/order";
import { LENGTH_ARMENIAN_NUMBER_WITHOUT_CODE } from "../../utils/constants";
import { Field, getFormValues, reduxForm, reset } from "redux-form";
import renderInput from "../html/renderInput";
import renderSelect from "../html/renderSelect";
import { normalizePhone } from "../../utils/validation";
import NoTrips from "../trip/NoTrips";
import { generateDropDown } from "../../utils/fakeData";
import { selectCities, setCities } from "../../reducers/city";
import { generateHHMM, readableDateTime } from "../../utils/date";

const states = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];
const MovingRequest = ({ handleSubmit }: { handleSubmit: any }) => {
  const [isCityRefreshing, setIsCityRefreshing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [isMovingAccepted, setIsMovingAccepted] = useState(false);
  const [isBetweenCities, setIsBetweenCities] = useState(false);
  const [isMovingPressed, setIsMovingPressed] = useState(false);

  const cities = useSelector(selectCities).map((city) => ({
    value: city.id,
    label: city.name,
  }));

  const movingCreate = useSelector<any, any>(getFormValues("movingCreate"));
  const createMoving = async (values: OrderCreateForm) => {
    setErrorMessage("");
    try {
      let dateISOString = "";
      if (values.date) {
        const selectedDate = availableDates().find(
          (date) => date.label === values.date
        );
        if (selectedDate) {
          dateISOString = selectedDate.value;
        }
      }
      await api.order.create({
        ...values,
        date: dateISOString,
        from: { ...values.from, id: values.from.id },
        to: {
          ...values.to,
          id: !isBetweenCities ? values.from.id : values.to.id,
        },
        type: TripType.MOVING,
        idDestination: 0,
        numberOfBookedCarSeats: 0,
      });
      setIsMovingPressed(true);
      setIsMovingAccepted(true);
      dispatch(reset("movingCreate"));
    } catch (error: any) {
      return setErrorMessage(error.message);
    }
  };
  const uploadCities = () => {
    setIsCityRefreshing(true);
    api.city
      .list()
      .then((list: any) => {
        dispatch(setCities(list));
        setIsCityRefreshing(false);
      })
      .catch((err: any) => {
        setIsCityRefreshing(false);
      });
  };

  useEffect(() => {
    uploadCities();
  }, []);

  if (isCityRefreshing) {
    return <CircularProgress color="secondary" />;
  }

  const isDateSet = movingCreate?.date !== undefined;
  const isTimeSet = movingCreate?.time !== undefined;
  const thisMoment = new Date();
  const isToday =
    new Date(movingCreate?.date).toLocaleDateString() ===
    thisMoment.toLocaleDateString();

  const availableDates = (): { value: string; label: string }[] => {
    const dates = [];
    const today = new Date();
    for (let m = 0; m <= 30; m++) {
      dates.push({
        value: today.toISOString(),
        label: readableDateTime(today.toISOString(), false),
      });
      today.setDate(today.getDate() + 1);
    }
    return dates;
  };

  const times = generateHHMM();

  const availableTimes = (() => {
    if (isToday) {
      return times.filter((times) => {
        const [h, t] = times.split(":");
        const templateTime = +h * 60 + +t;
        const thisTime = thisMoment.getHours() * 60 + thisMoment.getMinutes();
        return templateTime > thisTime;
      });
    }
    return times;
  })();
  return (
    <Card>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <CardHeader title="Заказать машину" />
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                disableFocusRipple
                checked={isBetweenCities}
                onChange={(e) => setIsBetweenCities(e.target.checked)}
              />
            }
            label="Между городами"
          />
        </Box>
      </Box>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid xs={12} md={6}>
            <Field
              fullWidth
              name="from.id"
              component={renderSelect}
              placeholder="Город забора груза"
              label="Город"
              list={cities}
              required
            />
          </Grid>
          <Grid xs={12} md={6}>
            {isBetweenCities && (
              <Field
                fullWidth
                name="to.id"
                component={renderSelect}
                placeholder="Город разгрузки груза"
                label="Куда"
                list={cities}
                required
              />
            )}
          </Grid>
          <Grid xs={12} md={6}>
            <Field
              name="date"
              component={renderSelect}
              label={"Дата поездки"}
              list={generateDropDown(
                availableDates().map((date) => date.label)
              )}
              required
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6}>
            {availableTimes.length > 0 ? (
              isDateSet ? (
                <Field
                  name="time"
                  component={renderSelect}
                  label={"Время отправления"}
                  list={generateDropDown(availableTimes)}
                  fullWidth
                  required
                />
              ) : null
            ) : (
              <NoTrips />
            )}
          </Grid>
          {isTimeSet ? (
            <>
              <Grid xs={12} md={6}>
                <Field
                  name="from.address"
                  component={renderInput}
                  label={`Откуда забрать`}
                  helperText=""
                  fullWidth
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Field
                  fullWidth
                  name="to.address"
                  component={renderInput}
                  label={`Куда привезти`}
                  required
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </CardContent>
      {isTimeSet ? (
        <>
          <Divider />
          <CardHeader title="Выбер мест" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <Field
                  fullWidth
                  name="numberOfBookedLuggages"
                  component={renderSelect}
                  label={"Выберите количество багажа"}
                  list={states}
                  required
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Field
                  fullWidth
                  name="numberOfBookedSeats"
                  component={renderSelect}
                  label={"Выберите количество грузчиков"}
                  list={states}
                  required
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardHeader title="Дополнительно" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid xs={12} md={12}>
                <Field
                  fullWidth
                  name="phone"
                  component={renderInput}
                  label={"Армянский контактный номер"}
                  required
                  maxLength={LENGTH_ARMENIAN_NUMBER_WITHOUT_CODE}
                  normalize={normalizePhone}
                />
              </Grid>
              <Grid xs={12} md={12}>
                <Field
                  fullWidth
                  name="comment"
                  component={renderInput}
                  label="Ваши пожелания"
                  multiline
                  minRows={4}
                />
              </Grid>
            </Grid>
          </CardContent>
          {errorMessage}
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={handleSubmit(createMoving)}
              disabled={isMovingPressed}
            >
              Отправить
            </Button>
          </CardActions>
        </>
      ) : null}
      {isMovingAccepted && (
        <Chip
          label={
            <>
              Ваш заказ принят!
              <br />
              Диспетчер свяжется с вами в ближайшее время, для подтверждения
              данных.
            </>
          }
          color="success"
        />
      )}
    </Card>
  );
};

const validate = (values: OrderCreateForm) => {
  const errors = {} as any;
  if (values) {
    if (values.date === "") {
      errors.date = "Поле обязательно к заполнению";
    }
    if (values.from?.address === "") {
      errors.from = { address: "Поле обязательно к заполнению" };
    }
    if (values.to?.address === "") {
      errors.to = { address: "Поле обязательно к заполнению" };
    }
    if (values.time === "") {
      errors.time = "Поле обязательно к заполнению";
    }
    if (
      values.phone &&
      values.phone.length !== LENGTH_ARMENIAN_NUMBER_WITHOUT_CODE
    ) {
      errors.phone = "Неверный номер";
    }
  }
  return errors;
};

export default reduxForm({
  form: "movingCreate",
  validate,
})(MovingRequest);

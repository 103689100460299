import axios from 'axios';
import { config } from '../../config';

export class AxiosConnector {
  public token = '';
  protected uri;
  protected axios = axios.create({
    baseURL: config.api.uri,
    timeout: 50000,
    headers: { authorization: `Bearer ` },
  });
  constructor() {
    this.uri = config.api.uri;
    this.updateToken('');
  }

  public updateToken(token: string) {
    this.token = token;
    this.axios = axios.create({
      baseURL: this.uri,
      timeout: 50000,
      headers: { authorization: `Bearer ${token}` },
    });
  }

  protected handleError = (err: any): void => {
    const origin = err.message;
    err.message =
      err.response?.data?.message || origin || 'Что-то пошло не так, обратитесь в раздел Контакты';
    throw err;
  };
}

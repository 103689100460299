import PropTypes from "prop-types";
import {
  // Avatar,
  Box,
  Divider,
  Drawer,
  Stack,
} from "@mui/material";
import { menuItems } from "./menu-items";
import { useLocation } from "react-router-dom";
import { NavigationItem } from "./navigation-item";

export const Navigation = (props: any) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* <Box sx={{ p: 3 }}>
          <Box
            component={NextLink}
            href="/"
            sx={{
              display: 'inline-flex',
              height: 32,
              width: 32
            }}
          >
            <Avatar
              src="/favicon-32x32.png"
              variant="square"
            />
          </Box> Dilijan Logistics
        </Box> */}
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 2,
          py: 3,
        }}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            p: 0,
            m: 0,
          }}
        >
          {menuItems.map((item: any) => {
            const active = item.path ? pathname === item.path : false;

            return (
              <NavigationItem
                active={active}
                disabled={item.disabled}
                external={item.external}
                icon={item.icon}
                key={item.title}
                path={item.path}
                title={item.title}
                closed={item.closed}
              />
            );
          })}
        </Stack>
      </Box>
      <Divider sx={{ borderColor: "secondary.main" }} />
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "primary.main",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

Navigation.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

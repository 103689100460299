import { createSlice } from '@reduxjs/toolkit';
import { Car } from '../sdk/interfaces';
import { RootState } from '../store';

interface carState {
  cars: Car[];
}

const initialState: carState = {
  cars: [],
};
export interface CarCreateForm {
  idDriver: number | null;
  licensePlate: string;
  color: string;
  numberOfSeats: number;
  numberOfLuggages: number;
  price: number;
  model: string;
}

export const initialCarCreateForm: CarCreateForm = {
  idDriver: null,
  licensePlate: '',
  color: '',
  numberOfSeats: 0,
  numberOfLuggages: 0,
  price: 0,
  model: '',
};

export const carReducer = createSlice({
  name: 'car',
  initialState,
  reducers: {
    setCars: (state, action) => {
      state.cars = action.payload;
    },
  },
});

export const { setCars } = carReducer.actions;

export const selectCars = (state: RootState): Car[] => state.car.cars;

export default carReducer.reducer;
